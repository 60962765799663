/* components/App.svelte generated by Svelte v3.21.0 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	mount_component,
	noop,
	outro_and_destroy_block,
	safe_not_equal,
	set_store_value,
	set_style,
	space,
	text,
	transition_in,
	transition_out,
	update_keyed_each
} from "svelte/internal";

import { onMount, onDestroy } from "svelte";
import uid from "uid";
import PhotoItem from "./PhotoItem.svelte";
import Sortable from "sortablejs/modular/sortable.core.esm.js";
import Icon from "../assets/icons.svelte";
import TabBar from "./TabBar.svelte";
import { sharedStates, imgCount } from "./store.js";

function add_css() {
	var style = element("style");
	style.id = "svelte-t6odig-style";
	style.textContent = ".root.svelte-t6odig.svelte-t6odig{padding:0;margin:0;display:flex;flex-direction:column;height:100%}.photo-frame.svelte-t6odig.svelte-t6odig{height:fit-content;border:none;text-align:center;padding:0;margin:0;background:var(--bgClr);display:flex;flex-direction:column;max-height:min(100vh, 100%)}.items-area.svelte-t6odig.svelte-t6odig{display:flex;flex-direction:column;width:100%;height:100%;border:none;padding:0}footer.svelte-t6odig.svelte-t6odig{justify-content:center;padding:0 12px;display:flex;height:var(--footerH);align-items:center;color:white}footer.svelte-t6odig a.svelte-t6odig{color:var(--clrLink);text-decoration:none;margin-left:0.4rem}";
	append(document.head, style);
}

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[19] = list[i];
	return child_ctx;
}

// (207:6) {#each itemIDs as id (id)}
function create_each_block(key_1, ctx) {
	let first;
	let current;

	const photoitem = new PhotoItem({
			props: {
				props: {
					cam: /*cam*/ ctx[7],
					id: /*id*/ ctx[19],
					itemsMap: /*itemsMap*/ ctx[3],
					delItem: /*delItem*/ ctx[11],
					setCurrentItemID: /*setCurrentItemID*/ ctx[9],
					currentItemID: /*currentItemID*/ ctx[4],
					currentShot: /*currentShot*/ ctx[5],
					setCurrentShot: /*setCurrentShot*/ ctx[8]
				}
			}
		});

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(photoitem.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(photoitem, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const photoitem_changes = {};

			if (dirty & /*itemIDs, itemsMap, currentItemID, currentShot*/ 60) photoitem_changes.props = {
				cam: /*cam*/ ctx[7],
				id: /*id*/ ctx[19],
				itemsMap: /*itemsMap*/ ctx[3],
				delItem: /*delItem*/ ctx[11],
				setCurrentItemID: /*setCurrentItemID*/ ctx[9],
				currentItemID: /*currentItemID*/ ctx[4],
				currentShot: /*currentShot*/ ctx[5],
				setCurrentShot: /*setCurrentShot*/ ctx[8]
			};

			photoitem.$set(photoitem_changes);
		},
		i(local) {
			if (current) return;
			transition_in(photoitem.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(photoitem.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(first);
			destroy_component(photoitem, detaching);
		}
	};
}

// (216:0) {#if isDebug}
function create_if_block(ctx) {
	let div1;
	let div0;

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			div0.textContent = "Debug info:";
			set_style(div0, "color", "red");
			set_style(div0, "width", "100%");
			set_style(div0, "height", "100%");
			attr(div1, "class", "debugger");
			set_style(div1, "position", "fixed");
			set_style(div1, "left", "10px");
			set_style(div1, "top", "25px");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			/*div0_binding_1*/ ctx[18](div0);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(div1);
			/*div0_binding_1*/ ctx[18](null);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let div1;
	let footer;
	let t0_value = "Built with 🧡 by " + "";
	let t0;
	let t1;
	let a;
	let t3;
	let div0;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let t4;
	let t5;
	let if_block_anchor;
	let current;
	let each_value = /*itemIDs*/ ctx[2];
	const get_key = ctx => /*id*/ ctx[19];

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block(key, child_ctx));
	}

	const tabbar = new TabBar({
			props: {
				props: {
					cam: /*cam*/ ctx[7],
					currentItemID: /*currentItemID*/ ctx[4],
					itemsMap: /*itemsMap*/ ctx[3],
					currentShot: /*currentShot*/ ctx[5],
					addItem: /*addItem*/ ctx[10],
					setCurrentShot: /*setCurrentShot*/ ctx[8]
				}
			}
		});

	let if_block = /*isDebug*/ ctx[6] && create_if_block(ctx);

	return {
		c() {
			div2 = element("div");
			div1 = element("div");
			footer = element("footer");
			t0 = text(t0_value);
			t1 = space();
			a = element("a");
			a.textContent = "@GnimOay";
			t3 = space();
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t4 = space();
			create_component(tabbar.$$.fragment);
			t5 = space();
			if (if_block) if_block.c();
			if_block_anchor = empty();
			attr(a, "href", "https://gnimoay.com");
			attr(a, "target", "_blank");
			attr(a, "class", "svelte-t6odig");
			attr(footer, "class", "svelte-t6odig");
			attr(div0, "class", "items-area svelte-t6odig");
			attr(div1, "class", "photo-frame svelte-t6odig");
			attr(div2, "class", "root svelte-t6odig");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div1);
			append(div1, footer);
			append(footer, t0);
			append(footer, t1);
			append(footer, a);
			append(div1, t3);
			append(div1, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div0, null);
			}

			/*div0_binding*/ ctx[17](div0);
			append(div2, t4);
			mount_component(tabbar, div2, null);
			insert(target, t5, anchor);
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*cam, itemIDs, itemsMap, delItem, setCurrentItemID, currentItemID, currentShot, setCurrentShot*/ 3004) {
				const each_value = /*itemIDs*/ ctx[2];
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, div0, outro_and_destroy_block, create_each_block, null, get_each_context);
				check_outros();
			}

			const tabbar_changes = {};

			if (dirty & /*currentItemID, itemsMap, currentShot*/ 56) tabbar_changes.props = {
				cam: /*cam*/ ctx[7],
				currentItemID: /*currentItemID*/ ctx[4],
				itemsMap: /*itemsMap*/ ctx[3],
				currentShot: /*currentShot*/ ctx[5],
				addItem: /*addItem*/ ctx[10],
				setCurrentShot: /*setCurrentShot*/ ctx[8]
			};

			tabbar.$set(tabbar_changes);
			if (/*isDebug*/ ctx[6]) if_block.p(ctx, dirty);
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			transition_in(tabbar.$$.fragment, local);
			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			transition_out(tabbar.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d();
			}

			/*div0_binding*/ ctx[17](null);
			destroy_component(tabbar);
			if (detaching) detach(t5);
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

let isFrontCam = false;

function instance($$self, $$props, $$invalidate) {
	let $sharedStates;
	let $imgCount;
	component_subscribe($$self, sharedStates, $$value => $$invalidate(12, $sharedStates = $$value));
	component_subscribe($$self, imgCount, $$value => $$invalidate(13, $imgCount = $$value));

	sharedStates.set({
		ifHideItemUI: true,
		ifHideTab: false,
		ifiOS: !!navigator.platform && (/iPad|iPhone|iPod/).test(navigator.platform)
	});

	const isDebug = window.location.hash === "#debug";

	//// debug ////////////
	let debug;

	function mylog(info) {
		console.log(isDebug, "dddbug", window.location.hash);
		if (!isDebug) return;
		$$invalidate(0, debug.innerHTML += `<div>${info}</div>`, debug);
	}

	//////////// debug ////
	let listElm;

	// let debugElm;
	let camList = [];

	let itemIDs = []; // to keep the order
	let itemsMap = {}; // our model with all data
	let currentItemID;
	let currentShot; // the shot() function from current PhotoItem

	let cam = {
		gotCamInfo: false,
		camInfo: undefined,
		isShooting: true,
		isFrontCam: false,
		// pendingShotFunc: undefined,
		stream: undefined,
		currentVidElm: undefined, // current <video> node for shooting
		switchCam: function switchCam() {
			this.isFrontCam = !this.isFrontCam;

			// itemsMap[currentItemID].isFrontCam = this.isFrontCam;
			// this.currentVidElm.srcObject.getTracks().forEach(t => t.stop());
			this.reqCam(this.isFrontCam).then(stream => this.currentVidElm.srcObject = stream);

			// copyItemsMap(); // to result in ui update
			console.log("switch cam", this.isFrontCam);
		},
		reqCam: function reqCam(isFront) {
			return navigator.mediaDevices.getUserMedia({
				video: $sharedStates.ifiOS
				? {
						facingMode: isFront ? "user" : "environment"
					}
				: {
						facingMode: isFront ? "user" : "environment", // width:
						width: 2600
					},
				audio: false
			}).then(stream => {
				this.stream = stream;
				return stream;
			});
		}
	};

	console.log("app", cam);

	function getIsShooting() {
		return itemsMap[currentItemID].isShooting;
	}

	// function setIsShooting(flag) {
	//   itemsMap[currentItemID].isShooting = flag;
	//   copyItemsMap();
	// }
	function setCurrentShot(shot) {
		$$invalidate(5, currentShot = shot);
	}

	function setCurrentItemID(id) {
		$$invalidate(4, currentItemID = id);
	}

	// function copyItemsMap() {
	//   itemStates.set(itemsMap);
	// }
	function addItem(item = undefined) {
		const id = uid();
		set_store_value(imgCount, $imgCount++, $imgCount);
		console.log($imgCount, "cccccc");
		mylog($imgCount);
		itemIDs.push(id);

		$$invalidate(
			3,
			itemsMap[id] = item || {
				orient: "h",
				text: "",
				isShooting: true,
				isFrontCam: cam.isFrontCam,
				isUiVisible: false,
				offsetX: 0,
				offsetY: 0,
				capTime: undefined, // time when captured
				
			},
			itemsMap
		);

		// copyItemsMap();
		// itemIds.set(itemIDs);
		$$invalidate(2, itemIDs);
	}

	function delItem(id) {
		console.log("before del", id, Object.assign({}, itemIDs));
		const index = itemIDs.indexOf(id);
		set_store_value(imgCount, $imgCount--, $imgCount);
		console.log($imgCount, "cccccc");
		itemIDs.splice(index, 1);

		// itemIds.set(itemIDs);
		$$invalidate(2, itemIDs);

		delete itemsMap[id];

		// copyItemsMap();
		console.log("after del", id, itemIDs);
	}

	onMount(async () => {
		const sortable = Sortable.create(listElm, {
			animation: 150,
			ghostClass: "photo-item-on-drag",
			handle: ".handle"
		});

		if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
			cam.reqCam(isFrontCam).then(() => {
				addItem();
			});
		}
	});

	onDestroy(() => {
		sortable.destroy();
		cam.stream.getTracks().forEach(t => t.stop());
		console.log("destroyed");
	});

	function div0_binding($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			$$invalidate(1, listElm = $$value);
		});
	}

	function div0_binding_1($$value) {
		binding_callbacks[$$value ? "unshift" : "push"](() => {
			$$invalidate(0, debug = $$value);
		});
	}

	return [
		debug,
		listElm,
		itemIDs,
		itemsMap,
		currentItemID,
		currentShot,
		isDebug,
		cam,
		setCurrentShot,
		setCurrentItemID,
		addItem,
		delItem,
		$sharedStates,
		$imgCount,
		mylog,
		camList,
		getIsShooting,
		div0_binding,
		div0_binding_1
	];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-t6odig-style")) add_css();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;