/* assets/icons.svelte generated by Svelte v3.21.0 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	svg_element
} from "svelte/internal";

function add_css() {
	var style = element("style");
	style.id = "svelte-197t0st-style";
	style.textContent = ".icon.svelte-197t0st{width:100%;height:100%;align-items:center;justify-content:center;display:flex}";
	append(document.head, style);
}

// (215:35) 
function create_if_block_18(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (201:27) 
function create_if_block_17(ctx) {
	let svg;
	let path0;
	let path1;

	return {
		c() {
			svg = svg_element("svg");
			path0 = svg_element("path");
			path1 = svg_element("path");
			attr(path0, "stroke-linecap", "round");
			attr(path0, "stroke-linejoin", "round");
			attr(path0, "stroke-width", "2");
			attr(path0, "d", "M15 12a3 3 0 11-6 0 3 3 0 016 0z");
			attr(path1, "stroke-linecap", "round");
			attr(path1, "stroke-linejoin", "round");
			attr(path1, "stroke-width", "2");
			attr(path1, "d", "M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274\n        4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path0);
			append(svg, path1);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (193:29) 
function create_if_block_16(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (182:28) 
function create_if_block_15(ctx) {
	let svg;
	let circle;

	return {
		c() {
			svg = svg_element("svg");
			circle = svg_element("circle");
			attr(circle, "cx", "12");
			attr(circle, "cy", "12");
			attr(circle, "r", "10");
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "stroke-width", "3px");
			attr(svg, "xmlns", "http://www.w3.org/2000/svg");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, circle);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (173:29) 
function create_if_block_14(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5\n        4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (165:30) 
function create_if_block_13(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M7 16V4m0 0L3 8m4-4l4 4m6 0v12m0 0l4-4m-4 4l-4-4");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (157:30) 
function create_if_block_12(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (149:28) 
function create_if_block_11(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M12 4v16m8-8H4");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (141:28) 
function create_if_block_10(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (121:27) 
function create_if_block_9(ctx) {
	let svg;
	let path0;
	let path1;

	return {
		c() {
			svg = svg_element("svg");
			path0 = svg_element("path");
			path1 = svg_element("path");
			attr(path0, "stroke-linecap", "round");
			attr(path0, "stroke-linejoin", "round");
			attr(path0, "stroke-width", "2");
			attr(path0, "d", "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573\n        1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426\n        1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37\n        2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724\n        1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0\n        00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0\n        001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07\n        2.572-1.065z");
			attr(path1, "stroke-linecap", "round");
			attr(path1, "stroke-linejoin", "round");
			attr(path1, "stroke-width", "2");
			attr(path1, "d", "M15 12a3 3 0 11-6 0 3 3 0 016 0z");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path0);
			append(svg, path1);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (112:30) 
function create_if_block_8(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0\n        0a8.003 8.003 0 01-15.357-2m15.357 2H15");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (104:35) 
function create_if_block_7(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (96:28) 
function create_if_block_6(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M15 19l-7-7 7-7");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (88:29) 
function create_if_block_5(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M9 5l7 7-7 7");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (80:36) 
function create_if_block_4(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (72:29) 
function create_if_block_3(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "stroke-linecap", "round");
			attr(path, "stroke-linejoin", "round");
			attr(path, "stroke-width", "2");
			attr(path, "d", "M5 13l4 4L19 7");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (57:30) 
function create_if_block_2(ctx) {
	let svg;
	let path0;
	let path1;

	return {
		c() {
			svg = svg_element("svg");
			path0 = svg_element("path");
			path1 = svg_element("path");
			attr(path0, "stroke-linecap", "round");
			attr(path0, "stroke-linejoin", "round");
			attr(path0, "stroke-width", "2");
			attr(path0, "d", "M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07\n        4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0\n        01-2 2H5a2 2 0 01-2-2V9z");
			attr(path1, "stroke-linecap", "round");
			attr(path1, "stroke-linejoin", "round");
			attr(path1, "stroke-width", "2");
			attr(path1, "d", "M15 13a3 3 0 11-6 0 3 3 0 016 0z");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "viewBox", "0 0 24 24");
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path0);
			append(svg, path1);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (47:30) 
function create_if_block_1(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "fill-rule", "evenodd");
			attr(path, "d", "M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707\n        7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414\n        0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1\n        1 0 010-1.414z");
			attr(path, "clip-rule", "evenodd");
			attr(svg, "viewBox", "0 0 20 20");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

// (39:2) {#if name === 'setting'}
function create_if_block(ctx) {
	let svg;
	let path;

	return {
		c() {
			svg = svg_element("svg");
			path = svg_element("path");
			attr(path, "d", "M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0\n        000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102\n        0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1\n        0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z");
			attr(svg, "viewBox", "0 0 20 20");
			attr(svg, "fill", /*fill*/ ctx[1]);
			attr(svg, "stroke", /*stroke*/ ctx[2]);
			attr(svg, "width", /*width*/ ctx[3]);
			attr(svg, "height", /*height*/ ctx[4]);
			attr(svg, "class", "w-8 h-8");
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, path);
		},
		p(ctx, dirty) {
			if (dirty & /*fill*/ 2) {
				attr(svg, "fill", /*fill*/ ctx[1]);
			}

			if (dirty & /*stroke*/ 4) {
				attr(svg, "stroke", /*stroke*/ ctx[2]);
			}

			if (dirty & /*width*/ 8) {
				attr(svg, "width", /*width*/ ctx[3]);
			}

			if (dirty & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
		}
	};
}

function create_fragment(ctx) {
	let div;

	function select_block_type(ctx, dirty) {
		if (/*name*/ ctx[0] === "setting") return create_if_block;
		if (/*name*/ ctx[0] === "change") return create_if_block_1;
		if (/*name*/ ctx[0] === "camera") return create_if_block_2;
		if (/*name*/ ctx[0] === "check") return create_if_block_3;
		if (/*name*/ ctx[0] === "check-circle") return create_if_block_4;
		if (/*name*/ ctx[0] === "right") return create_if_block_5;
		if (/*name*/ ctx[0] === "left") return create_if_block_6;
		if (/*name*/ ctx[0] === "dotc-circle") return create_if_block_7;
		if (/*name*/ ctx[0] === "switch") return create_if_block_8;
		if (/*name*/ ctx[0] === "cog") return create_if_block_9;
		if (/*name*/ ctx[0] === "save") return create_if_block_10;
		if (/*name*/ ctx[0] === "plus") return create_if_block_11;
		if (/*name*/ ctx[0] === "draglr") return create_if_block_12;
		if (/*name*/ ctx[0] === "dragud") return create_if_block_13;
		if (/*name*/ ctx[0] === "trash") return create_if_block_14;
		if (/*name*/ ctx[0] === "ring") return create_if_block_15;
		if (/*name*/ ctx[0] === "cross") return create_if_block_16;
		if (/*name*/ ctx[0] === "eye") return create_if_block_17;
		if (/*name*/ ctx[0] === "plus-circle") return create_if_block_18;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type && current_block_type(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			attr(div, "class", "icon svelte-197t0st");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if (if_block) if_block.d(1);
				if_block = current_block_type && current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);

			if (if_block) {
				if_block.d();
			}
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { name = "" } = $$props;
	let { fill = "none" } = $$props;
	let { stroke = "currentColor" } = $$props;
	let { width = 34 } = $$props;
	let { height = 34 } = $$props;

	$$self.$set = $$props => {
		if ("name" in $$props) $$invalidate(0, name = $$props.name);
		if ("fill" in $$props) $$invalidate(1, fill = $$props.fill);
		if ("stroke" in $$props) $$invalidate(2, stroke = $$props.stroke);
		if ("width" in $$props) $$invalidate(3, width = $$props.width);
		if ("height" in $$props) $$invalidate(4, height = $$props.height);
	};

	return [name, fill, stroke, width, height];
}

class Icons extends SvelteComponent {
	constructor(options) {
		super();
		if (!document.getElementById("svelte-197t0st-style")) add_css();

		init(this, options, instance, create_fragment, safe_not_equal, {
			name: 0,
			fill: 1,
			stroke: 2,
			width: 3,
			height: 4
		});
	}
}

export default Icons;