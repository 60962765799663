import App from '~/components/App.svelte'

const app = new App({
  target: document.body,
  props: {
    appName: 'STKM - Your Photo Diary',
  },
})

window.app = app

export default app
